import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import ceVSdm from "../../../../contents/posts/39-website-editor/1.png";
import designMode from "../../../../contents/posts/39-website-editor/2.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  ceVSdm,
  designMode,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import EnterDomain from "../../../../contents/posts/35-oauth2-using-auth0/1.png";
import CreateApplication from "../../../../contents/posts/35-oauth2-using-auth0/2.png";
import ApplicationUri from "../../../../contents/posts/35-oauth2-using-auth0/3.png";
import EnableSocial from "../../../../contents/posts/35-oauth2-using-auth0/4.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  EnterDomain,
  CreateApplication,
  ApplicationUri,
  EnableSocial,
  React
};
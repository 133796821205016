import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import Inline from "../../../../contents/posts/66-css-inline-block-inlineblock/1.png";
import Block from "../../../../contents/posts/66-css-inline-block-inlineblock/2.png";
import InlineBlock from "../../../../contents/posts/66-css-inline-block-inlineblock/3.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  Inline,
  Block,
  InlineBlock,
  React
};
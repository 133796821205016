import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import MutationSchema from "../../../../contents/posts/1-aws-appsync-integration-with-react-native-part-1/1.png";
import Queries from "../../../../contents/posts/1-aws-appsync-integration-with-react-native-part-1/2.png";
import Table from "../../../../contents/posts/1-aws-appsync-integration-with-react-native-part-1/3.png";
import MutationQuery from "../../../../contents/posts/1-aws-appsync-integration-with-react-native-part-1/4.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  MutationSchema,
  Queries,
  Table,
  MutationQuery,
  React
};
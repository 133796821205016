import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Setting from "../../../../contents/posts/43-deploying-gatsby-using-github-action-route-53/1-setting.png";
import Secrets from "../../../../contents/posts/43-deploying-gatsby-using-github-action-route-53/2-secrets.png";
import NewSecret from "../../../../contents/posts/43-deploying-gatsby-using-github-action-route-53/3-new-secret.png";
import CheckValueName from "../../../../contents/posts/43-deploying-gatsby-using-github-action-route-53/4-check-value-name.png";
import Actions from "../../../../contents/posts/43-deploying-gatsby-using-github-action-route-53/5-actions.png";
import HostedZone from "../../../../contents/posts/43-deploying-gatsby-using-github-action-route-53/6-hosted-zone.png";
import IP from "../../../../contents/posts/43-deploying-gatsby-using-github-action-route-53/7-ip.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Setting,
  Secrets,
  NewSecret,
  CheckValueName,
  Actions,
  HostedZone,
  IP,
  React
};
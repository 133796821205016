import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import css1 from "../../../../contents/posts/60-css-placeholder-and-placeholder-shown/1.gif";
import css2 from "../../../../contents/posts/60-css-placeholder-and-placeholder-shown/2.png";
import css3 from "../../../../contents/posts/60-css-placeholder-and-placeholder-shown/3.png";
import css4 from "../../../../contents/posts/60-css-placeholder-and-placeholder-shown/4.gif";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  css1,
  css2,
  css3,
  css4,
  React
};
import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import FlexBoxFroggy1 from "../../../../contents/posts/61-learn-css-by-playing-games/1-froggy.jpeg";
import FlexZombies2 from "../../../../contents/posts/61-learn-css-by-playing-games/2-zombies.jpeg";
import FlexboxDefence3 from "../../../../contents/posts/61-learn-css-by-playing-games/3-defence.jpeg";
import GridGarden4 from "../../../../contents/posts/61-learn-css-by-playing-games/4-gridgarden.jpeg";
import CSSBattle5 from "../../../../contents/posts/61-learn-css-by-playing-games/5-cssbattle.png";
import CSSDiner6 from "../../../../contents/posts/61-learn-css-by-playing-games/6-css-diner.jpeg";
import CodePip7 from "../../../../contents/posts/61-learn-css-by-playing-games/7-codepip.png";
import CodeMonkey8 from "../../../../contents/posts/61-learn-css-by-playing-games/8-code-monkey.png";
import CodingGame9 from "../../../../contents/posts/61-learn-css-by-playing-games/9-coding-game.png";
import CodeCombat10 from "../../../../contents/posts/61-learn-css-by-playing-games/10-code-combat.png";
import Roadmap11 from "../../../../contents/posts/61-learn-css-by-playing-games/11-roadmap.png";
import Carnival12 from "../../../../contents/posts/61-learn-css-by-playing-games/12-carnival.png";
import ServiceWorkies13 from "../../../../contents/posts/61-learn-css-by-playing-games/13-service-workies.png";
import Unfold14 from "../../../../contents/posts/61-learn-css-by-playing-games/14-unflod.png";
import GridCutters15 from "../../../../contents/posts/61-learn-css-by-playing-games/15-grid-critters.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  FlexBoxFroggy1,
  FlexZombies2,
  FlexboxDefence3,
  GridGarden4,
  CSSBattle5,
  CSSDiner6,
  CodePip7,
  CodeMonkey8,
  CodingGame9,
  CodeCombat10,
  Roadmap11,
  Carnival12,
  ServiceWorkies13,
  Unfold14,
  GridCutters15,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Movie from "../../../../contents/posts/77-getting-started-with-web3-for-frontend/1.png";
import Roadmap from "../../../../contents/posts/77-getting-started-with-web3-for-frontend/2.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Movie,
  Roadmap,
  React
};
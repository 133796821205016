import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Normal from "../../../../contents/posts/30-css-blend-mode/1_normal.png";
import Multiply from "../../../../contents/posts/30-css-blend-mode/2_multiply.png";
import Screen from "../../../../contents/posts/30-css-blend-mode/3_screen.png";
import Overlay from "../../../../contents/posts/30-css-blend-mode/4_overlay.png";
import Darken from "../../../../contents/posts/30-css-blend-mode/5_darken.png";
import Lighten from "../../../../contents/posts/30-css-blend-mode/6_lighten.png";
import ColorDodge from "../../../../contents/posts/30-css-blend-mode/7_color-dodge.png";
import ColorBurn from "../../../../contents/posts/30-css-blend-mode/8_color-burn.png";
import HardLight from "../../../../contents/posts/30-css-blend-mode/9_hard-light.png";
import SoftLight from "../../../../contents/posts/30-css-blend-mode/10_soft-light.png";
import Difference from "../../../../contents/posts/30-css-blend-mode/11_difference.png";
import Exclusion from "../../../../contents/posts/30-css-blend-mode/12_exclusion.png";
import Hue from "../../../../contents/posts/30-css-blend-mode/13_hue.png";
import Luminosity from "../../../../contents/posts/30-css-blend-mode/14_luminosity.png";
import Isolate from "../../../../contents/posts/30-css-blend-mode/isolate.png";
import OutputGif from "../../../../contents/posts/30-css-blend-mode/output_gif.gif";
import FriendsGif from "../../../../contents/posts/30-css-blend-mode/friends.gif";
import MixBlendModeGif from "../../../../contents/posts/30-css-blend-mode/mix-blend-mode.gif";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Normal,
  Multiply,
  Screen,
  Overlay,
  Darken,
  Lighten,
  ColorDodge,
  ColorBurn,
  HardLight,
  SoftLight,
  Difference,
  Exclusion,
  Hue,
  Luminosity,
  Isolate,
  OutputGif,
  FriendsGif,
  MixBlendModeGif,
  React
};
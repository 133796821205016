import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Output from "../../../../contents/posts/33-css-backdrop-filter/1.png";
import BrowserSupport from "../../../../contents/posts/33-css-backdrop-filter/2-browser-support.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Output,
  BrowserSupport,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import NoInternet1 from "../../../../contents/posts/22-offline-notice-in-react-native/1-no-internet-connection.png";
import NoInternet2 from "../../../../contents/posts/22-offline-notice-in-react-native/2-no-internet-connection.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  NoInternet1,
  NoInternet2,
  React
};
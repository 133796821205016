import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import TurboConsole from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/turbo-console.png";
import Quokka from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/quokka.png";
import Prettier from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/prettier.png";
import LiveShare from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/liveshare.png";
import GitLens from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/gitlens.png";
import PolaCode from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/polacode.png";
import VSCodeIcons from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/vscode-icons.png";
import OpenInBrowser from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/open-browser.png";
import EsLint from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/eslint.png";
import MarkDownPreview from "../../../../contents/posts/32-10-vs-code-extensions-for-frontend-engineer/markdown.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  TurboConsole,
  Quokka,
  Prettier,
  LiveShare,
  GitLens,
  PolaCode,
  VSCodeIcons,
  OpenInBrowser,
  EsLint,
  MarkDownPreview,
  React
};
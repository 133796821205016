import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Post from "../../../../contents/posts/41-basic-of-gatsby/1-post.png";
import Analyzer from "../../../../contents/posts/41-basic-of-gatsby/2-analyze.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Post,
  Analyzer,
  React
};
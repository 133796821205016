import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Pointers from "../../../../contents/posts/19-basics-of-golang-part-3/1-pointers.png";
import ErrorHandling from "../../../../contents/posts/19-basics-of-golang-part-3/2-error.png";
import PanicDefer from "../../../../contents/posts/19-basics-of-golang-part-3/3-panic-defer.png";
import Method from "../../../../contents/posts/19-basics-of-golang-part-3/4-method.png";
import Interface from "../../../../contents/posts/19-basics-of-golang-part-3/5-interface.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Pointers,
  ErrorHandling,
  PanicDefer,
  Method,
  Interface,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import FunctionImg from "../../../../contents/posts/18-basics-of-golang-part-2/1-function.png";
import Toolkit from "../../../../contents/posts/18-basics-of-golang-part-2/2-toolkit.png";
import Packages from "../../../../contents/posts/18-basics-of-golang-part-2/3-packages.png";
import UnitTesting from "../../../../contents/posts/18-basics-of-golang-part-2/4-unit-testing.png";
import Structs from "../../../../contents/posts/18-basics-of-golang-part-2/5-structs.jpg";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  FunctionImg,
  Toolkit,
  Packages,
  UnitTesting,
  Structs,
  React
};
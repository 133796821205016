import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import ContainerQuery1 from "../../../../contents/posts/62-css-container-query/1.png";
import ContainerQuery2 from "../../../../contents/posts/62-css-container-query/2.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  ContainerQuery1,
  ContainerQuery2,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Gif1 from "../../../../contents/posts/3-adoptive-headers-using-css/1.gif";
import Gif2 from "../../../../contents/posts/3-adoptive-headers-using-css/2.gif";
import Gif3 from "../../../../contents/posts/3-adoptive-headers-using-css/3.gif";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Gif1,
  Gif2,
  Gif3,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import Blur from "../../../../contents/posts/27-css-filter/1-blur.png";
import Brightness from "../../../../contents/posts/27-css-filter/2-brightness.png";
import Contrast from "../../../../contents/posts/27-css-filter/3-contrast.png";
import DropShadow from "../../../../contents/posts/27-css-filter/4-drop-shadow.png";
import Grayscale from "../../../../contents/posts/27-css-filter/5-grayscale.png";
import HueRotate from "../../../../contents/posts/27-css-filter/6-hue-rotate.png";
import Invert from "../../../../contents/posts/27-css-filter/7-invert.png";
import Opacity from "../../../../contents/posts/27-css-filter/8-opacity.png";
import Saturate from "../../../../contents/posts/27-css-filter/9-saturate.png";
import Sepia from "../../../../contents/posts/27-css-filter/10-sepia.png";
import MultipleProperty from "../../../../contents/posts/27-css-filter/11-multiple-property.png";
import ColorWheel from "../../../../contents/posts/27-css-filter/color-wheel.png";
import GifFilter from "../../../../contents/posts/27-css-filter/css-filter-banner.gif";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  Blur,
  Brightness,
  Contrast,
  DropShadow,
  Grayscale,
  HueRotate,
  Invert,
  Opacity,
  Saturate,
  Sepia,
  MultipleProperty,
  ColorWheel,
  GifFilter,
  React
};
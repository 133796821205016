import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import JsConsole1 from "../../../../contents/posts/58-use-javascript-console-like-pro/1.png";
import JsConsole2 from "../../../../contents/posts/58-use-javascript-console-like-pro/2.png";
import JsConsole3 from "../../../../contents/posts/58-use-javascript-console-like-pro/3.png";
import JsConsole4 from "../../../../contents/posts/58-use-javascript-console-like-pro/4.png";
import JsConsole5 from "../../../../contents/posts/58-use-javascript-console-like-pro/5.png";
import JsConsole6 from "../../../../contents/posts/58-use-javascript-console-like-pro/6.png";
import JsConsole7 from "../../../../contents/posts/58-use-javascript-console-like-pro/7.png";
import JsConsole8 from "../../../../contents/posts/58-use-javascript-console-like-pro/8.png";
import JsConsole9 from "../../../../contents/posts/58-use-javascript-console-like-pro/9.png";
import JsConsole10 from "../../../../contents/posts/58-use-javascript-console-like-pro/10.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  JsConsole1,
  JsConsole2,
  JsConsole3,
  JsConsole4,
  JsConsole5,
  JsConsole6,
  JsConsole7,
  JsConsole8,
  JsConsole9,
  JsConsole10,
  React
};
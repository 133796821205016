import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import BorderLineStart from "../../../../contents/posts/48-css-border/1.png";
import BorderLineEnd from "../../../../contents/posts/48-css-border/2.png";
import BorderInlineStyle from "../../../../contents/posts/48-css-border/3.png";
import BorderBlockStart from "../../../../contents/posts/48-css-border/4.png";
import BorderBlockEnd from "../../../../contents/posts/48-css-border/5.png";
import BorderImage from "../../../../contents/posts/48-css-border/6.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  BorderLineStart,
  BorderLineEnd,
  BorderInlineStyle,
  BorderBlockStart,
  BorderBlockEnd,
  BorderImage,
  React
};
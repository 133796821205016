import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import TypingGo from "../../../../contents/posts/17-basics-of-golang-part-1/1.gif";
import DataTypes from "../../../../contents/posts/17-basics-of-golang-part-1/2-data-types.png";
import Variables from "../../../../contents/posts/17-basics-of-golang-part-1/3-variables.png";
import ControlStatement from "../../../../contents/posts/17-basics-of-golang-part-1/4-control-statement.png";
import Switch from "../../../../contents/posts/17-basics-of-golang-part-1/5-switch.jpg";
import ForLoop from "../../../../contents/posts/17-basics-of-golang-part-1/6-for.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  TypingGo,
  DataTypes,
  Variables,
  ControlStatement,
  Switch,
  ForLoop,
  React
};
import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import HowJSWorks1 from "../../../../contents/posts/55-how-javascript-works/1.jpg";
import HowJSWorks2 from "../../../../contents/posts/55-how-javascript-works/2.png";
import HowJSWorks3 from "../../../../contents/posts/55-how-javascript-works/3.jpeg";
import HowJSWorks4 from "../../../../contents/posts/55-how-javascript-works/4.png";
import HowJSWorks5 from "../../../../contents/posts/55-how-javascript-works/5.png";
import HowJSWorks6 from "../../../../contents/posts/55-how-javascript-works/6.png";
import HowJSWorks7 from "../../../../contents/posts/55-how-javascript-works/7.gif";
import HowJSWorks8 from "../../../../contents/posts/55-how-javascript-works/8.png";
import HowJSWorks9 from "../../../../contents/posts/55-how-javascript-works/9.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  HowJSWorks1,
  HowJSWorks2,
  HowJSWorks3,
  HowJSWorks4,
  HowJSWorks5,
  HowJSWorks6,
  HowJSWorks7,
  HowJSWorks8,
  HowJSWorks9,
  React
};
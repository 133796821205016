import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import BoxReflect1 from "../../../../contents/posts/63-css-box-reflect/1.png";
import BoxReflectSupport1 from "../../../../contents/posts/63-css-box-reflect/2.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  BoxReflect1,
  BoxReflectSupport1,
  React
};
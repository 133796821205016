import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import FolderStructure from "../../../../contents/posts/4-react-native-app-using-expo/1.png";
import ExpoBuild from "../../../../contents/posts/4-react-native-app-using-expo/2.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  FolderStructure,
  ExpoBuild,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import WebServer from "../../../../contents/posts/21-basics-of-golang-part-4/1-web-server.png";
import ExternalAPI from "../../../../contents/posts/21-basics-of-golang-part-4/2-external-api.jpg";
import Concurrency from "../../../../contents/posts/21-basics-of-golang-part-4/3-concurrency.png";
import ConcurrencyB from "../../../../contents/posts/21-basics-of-golang-part-4/4-concurrency-b.jpg";
import Channel from "../../../../contents/posts/21-basics-of-golang-part-4/5-channel.jpg";
import TodoOutput from "../../../../contents/posts/21-basics-of-golang-part-4/6-todo-output.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  WebServer,
  ExternalAPI,
  Concurrency,
  ConcurrencyB,
  Channel,
  TodoOutput,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import GoToConsole from "../../../../contents/posts/28-login-via-firebase/1.png";
import StartProject from "../../../../contents/posts/28-login-via-firebase/2.png";
import Analytics from "../../../../contents/posts/28-login-via-firebase/3.png";
import ConfigureAnalytics from "../../../../contents/posts/28-login-via-firebase/4.png";
import ProjectOverview from "../../../../contents/posts/28-login-via-firebase/5.png";
import AddFirebaseWebApp from "../../../../contents/posts/28-login-via-firebase/6.png";
import ProjectSettings from "../../../../contents/posts/28-login-via-firebase/7.png";
import EnableEmailPassword from "../../../../contents/posts/28-login-via-firebase/8.png";
import AddEmailPassword from "../../../../contents/posts/28-login-via-firebase/9.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  GoToConsole,
  StartProject,
  Analytics,
  ConfigureAnalytics,
  ProjectOverview,
  AddFirebaseWebApp,
  ProjectSettings,
  EnableEmailPassword,
  AddEmailPassword,
  React
};
import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import NewSite from "../../../../contents/posts/42-deploying-gatsby-on-netlify/1-new-site.png";
import SelectGithub from "../../../../contents/posts/42-deploying-gatsby-on-netlify/2-select-github.png";
import InstallNetlify from "../../../../contents/posts/42-deploying-gatsby-on-netlify/3-install-netlify.png";
import SelectProject from "../../../../contents/posts/42-deploying-gatsby-on-netlify/4-select-project.png";
import DeploySite from "../../../../contents/posts/42-deploying-gatsby-on-netlify/5-deploy-site.png";
import CheckBuildStatus from "../../../../contents/posts/42-deploying-gatsby-on-netlify/6-check-build-status.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  NewSite,
  SelectGithub,
  InstallNetlify,
  SelectProject,
  DeploySite,
  CheckBuildStatus,
  React
};
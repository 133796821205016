import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import CursorCSS1 from "../../../../contents/posts/59-changing-cursor-with-css-for-better-ux/1.gif";
import CursorCSS2 from "../../../../contents/posts/59-changing-cursor-with-css-for-better-ux/2.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  CursorCSS1,
  CursorCSS2,
  React
};
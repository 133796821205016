import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import One from "../../../../contents/posts/24-css-box-shadow/one.png";
import Two from "../../../../contents/posts/24-css-box-shadow/two.png";
import Three from "../../../../contents/posts/24-css-box-shadow/three.png";
import Four from "../../../../contents/posts/24-css-box-shadow/four.png";
import Five from "../../../../contents/posts/24-css-box-shadow/five.png";
import Six from "../../../../contents/posts/24-css-box-shadow/six.png";
import Seven from "../../../../contents/posts/24-css-box-shadow/seven.png";
import Eight from "../../../../contents/posts/24-css-box-shadow/eight.png";
import CircleOutput from "../../../../contents/posts/24-css-box-shadow/circle.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  CircleOutput,
  React
};
import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import CSSCombinator from "../../../../contents/posts/47-css-selectors/1-css-combinator.png";
import Descendant from "../../../../contents/posts/47-css-selectors/2-descendant.png";
import Universal from "../../../../contents/posts/47-css-selectors/3-universal.png";
import AdjacentSibling from "../../../../contents/posts/47-css-selectors/4-adjacent-sibling.png";
import GeneralSibling from "../../../../contents/posts/47-css-selectors/5-general-sibling.png";
import Child from "../../../../contents/posts/47-css-selectors/6-child.png";
import Value from "../../../../contents/posts/47-css-selectors/7-value.png";
import Start from "../../../../contents/posts/47-css-selectors/8-start.png";
import Ends from "../../../../contents/posts/47-css-selectors/9-ends.png";
import Wildcard from "../../../../contents/posts/47-css-selectors/10-wildcard.png";
import CSSAttributes from "../../../../contents/posts/47-css-selectors/11-css-attributes.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  CSSCombinator,
  Descendant,
  Universal,
  AdjacentSibling,
  GeneralSibling,
  Child,
  Value,
  Start,
  Ends,
  Wildcard,
  CSSAttributes,
  React
};
import LinkPost from "../../../../src/components/linkPost";
import ImgPost from "../../../../src/components/imgPost";
import CheckIo from "../../../../contents/posts/67-learn-javascript-by-playing-games/1.png";
import JsRobot from "../../../../contents/posts/67-learn-javascript-by-playing-games/2.png";
import JsDares from "../../../../contents/posts/67-learn-javascript-by-playing-games/3.png";
import CodingGame from "../../../../contents/posts/67-learn-javascript-by-playing-games/4.png";
import Robocode from "../../../../contents/posts/67-learn-javascript-by-playing-games/5.png";
import CodeCombat from "../../../../contents/posts/67-learn-javascript-by-playing-games/6.png";
import Screeps from "../../../../contents/posts/67-learn-javascript-by-playing-games/7.png";
import Crunchzilla from "../../../../contents/posts/67-learn-javascript-by-playing-games/8.png";
import ElevatorSage from "../../../../contents/posts/67-learn-javascript-by-playing-games/9.png";
import Untrusted from "../../../../contents/posts/67-learn-javascript-by-playing-games/10.png";
import * as React from 'react';
export default {
  LinkPost,
  ImgPost,
  CheckIo,
  JsRobot,
  JsDares,
  CodingGame,
  Robocode,
  CodeCombat,
  Screeps,
  Crunchzilla,
  ElevatorSage,
  Untrusted,
  React
};
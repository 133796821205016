import ImgPost from "../../../../src/components/imgPost";
import LinkPost from "../../../../src/components/linkPost";
import SphereGif from "../../../../contents/posts/13-three-js-part-2/1.gif";
import Stat from "../../../../contents/posts/13-three-js-part-2/2.gif";
import Triangle from "../../../../contents/posts/13-three-js-part-2/3.png";
import TrianglesType from "../../../../contents/posts/13-three-js-part-2/4.png";
import * as React from 'react';
export default {
  ImgPost,
  LinkPost,
  SphereGif,
  Stat,
  Triangle,
  TrianglesType,
  React
};